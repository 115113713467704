import React from 'react';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import { Chevron } from '@latitude/chevron';
import { BREAKPOINT, PADDING, COLOR } from '../../utils/constants';
import { CardGroup, CardGroupItem } from '../CardGroup/CardGroup';
import { Heading3, Heading6 } from '../Heading/Heading';
import SvgInline from '../SvgInline/SvgInline';
import { Box } from '../Box/Box';

export default ({ loans1 }) => {
  const heading = 'Calculators';
  const href = loans1
    ? '/personal-loan-repayment-calculator1/'
    : '/personal-loan-repayment-calculator/';
  const OuterBox = styled(Box)`
    background-color: #f8f8f8;
    padding-top: 24px;
    padding-bottom: 24px;
    @media (min-width: ${BREAKPOINT.LG}) {
      padding-top: 72px;
      padding-bottom: 72px;
      background-image: url(${require('./images/bg-calculators.svg').default});
      background-position: center;
    }
  `;

  const InnerBox = styled(Box)`
    margin: 0 auto;
  `;

  const Title = styled(Heading3)`
    text-align: center;
    color: #000;
    font-size: 24px;

    @media (min-width: ${BREAKPOINT.LG}) {
      font-size: 32px;
    }
  `;

  const ItemLink = styled(Link)`
    /* height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 96px;
    padding: ${PADDING.P24};
    background-color: ${COLOR.WHITE};
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    transition: all 150ms ease-in-out;

    &:hover {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.49);
      transition: all 150ms ease-in-out;
    }
  `;

  const ItemIcon = styled(SvgInline)`
    width: 40px;
    min-width: 40px;
    margin-right: 13px;
  `;

  const ItemTitle = styled(Heading6)`
    margin-bottom: 0;
    flex-grow: 1;
    color: ${COLOR.BLACK};
    @media (min-width: ${BREAKPOINT.XL}) {
      font-size: 20px;
    }
  `;

  const ItemChevron = styled(Chevron)`
    /* svg .chevron-line.icon-line--style {
      stroke: ${COLOR.BLUE};
    } */
  `;

  return (
    <>
      <OuterBox>
        <InnerBox isResponsive>
          <Title>{heading}</Title>
          <CardGroup>
            <CardGroupItem key={1} col={3}>
              <ItemLink
                noStyle
                href={href}
                trackId="calculator-link-personal-loan-repayment-calculator"
                trackEventData={{
                  label: 'Personal loan repayment calculator',
                  location: heading
                }}
              >
                <ItemIcon name="icon-calc-pl" />
                <ItemTitle>Personal loan repayment calculator</ItemTitle>
                <ItemChevron direction="right" color={COLOR.BLUE} />
              </ItemLink>
            </CardGroupItem>
            <CardGroupItem key={2} col={3}>
              <ItemLink
                noStyle
                href="/budget-planner/"
                trackId="calculator-link-budget-planner"
                trackEventData={{
                  label: 'Budget planner',
                  location: heading
                }}
              >
                <ItemIcon name="icon-calc-budget" />
                <ItemTitle>Budget planner</ItemTitle>
                <ItemChevron direction="right" color={COLOR.BLUE} />
              </ItemLink>
            </CardGroupItem>
          </CardGroup>
        </InnerBox>
      </OuterBox>
    </>
  );
};
